.navContainer {
    font-size: 20px;
    padding: 12px 1.8rem;
}


.heroSectionButton {
    line-height: 1.5;
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
    text-decoration: inherit;
    padding: 1rem;
    font-weight: 700;
    background-color: #35519f;
    color: rgb(247, 250, 252);
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 300ms;
    margin-top: 2rem;
    display: inline-block;
    width: 14rem;
    letter-spacing: 0.025em;
    text-align: center;
    border-radius: 9999px;
    text-decoration: none;
}

.heroSectionButton:hover {
    background-color: #152140;
    color: rgb(237, 242, 247);
    text-decoration: none;
}

.heroSectionButtonSecondary {
    background-color: #4e4f51;
}

.demoButtonHome {
    background-color: #5b5959bd;
}

.demoButtonHome:hover {
    background-color: #4e4f54;
}


.heroLogo {
    width: 280px !important;
}

.buttonicon {
    padding-right: 10px;
    width: 40px;
}

.buttoniconreact {
    padding-left: 10px;
    width: 50px;
}

.primaryText {
    font-size: 18px !important;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .navContainer {
        padding: 10px;
    }

    .heroLogo {
        width: 120px !important;
    }

    .heroLogo {
        display: none;
    }

    .heroDecoratorBlob {
        display: none;
    }

    .logo {
        font-size: 30px;
    }

    .navImage {
        width: 57px;
        margin-left: 15px;
    }

    .FeaturesContainer {
        padding-top: 0 !important;
    }

    .navContainer {
        font-size: 1rem !important;
    }

    .App {
        margin-top: 50px;
    }

    .homeSectionTitle {
        text-align: center !important;
    }

    .homeFeatureCard {
        padding-top: 0 !important;
        padding-bottom: 20px !important;
    }

    .primaryText {
        font-size: 17px !important;
    }
}
